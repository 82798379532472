/* ---------------------- */
/* --- ROOT VARIABLES --- */
/* ---------------------- */

:root {
  /* ANIMATION DEFINITIONS */
  --text-sprite-animation: .35s steps(2, end) 0s infinite normal none;
  --dance-animation: .75s steps(2, end) 0s infinite normal none;

  /* TEXT SPRITE COORDINATES */
  /* ROWS */
  --text-sprite-row-one: 1%;
  --text-sprite-row-two: 9.5%;
  --text-sprite-row-three: 18%;
  --text-sprite-row-four: 26.5%;
  --text-sprite-row-five: 42%;
  --text-sprite-row-six: 50.5%;
  --text-sprite-row-seven: 65%;
  --text-sprite-row-eight: 74%;
  --text-sprite-row-nine: 83%;
  /* COLS */
  --text-sprite-col-one: 1.75%;
  --text-sprite-col-two: 8%;
  --text-sprite-col-three: 15%;
  --text-sprite-col-four: 23%;
  --text-sprite-col-five: 29%;
  --text-sprite-col-six: 36%;
  --text-sprite-col-seven: 42%;
  --text-sprite-col-eight: 49%;
  --text-sprite-col-nine: 56%;
  --text-sprite-col-ten: 63%;
  --text-sprite-col-eleven: 70.5%;
  --text-sprite-col-twelve: 77%;
  --text-sprite-col-thirteen: 84%;
  --text-sprite-col-fourteen: 91%;
  --text-sprite-col-fifteen: 98%;
}

/* ------------------ */
/* --- PAUSE MENU --- */
/* ------------------ */

#space-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-image: url(./assets/space-background.png);
  background-repeat: no-repeat;
  background-position: top 0% left 0%;
  background-size: cover;
}
#jam-out-title1 {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#jam-out-title1 .jam-out-title-letter {
  display: inline-block;
  height: 34px;
  width: 30px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 500px auto;
}
#jam-out-title1 .jam-out-title-letter:nth-child(1) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title1 .jam-out-title-letter:nth-child(2) { background-position: top 1000% left 1000%; }
#jam-out-title1 .jam-out-title-letter:nth-child(3) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-ten); }
#jam-out-title1 .jam-out-title-letter:nth-child(4) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#jam-out-title1 .jam-out-title-letter:nth-child(5) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#jam-out-title1 .jam-out-title-letter:nth-child(6) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }
#jam-out-title1 .jam-out-title-letter:nth-child(7) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-four); }
#jam-out-title1 .jam-out-title-letter:nth-child(8) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-six); }
#jam-out-title1 .jam-out-title-letter:nth-child(9) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#jam-out-title1 .jam-out-title-letter:nth-child(10) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-three); }
#jam-out-title1 .jam-out-title-letter:nth-child(11) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-four); }
#jam-out-title1 .jam-out-title-letter:nth-child(12) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-six); }
#jam-out-title1 .jam-out-title-letter:nth-child(13) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#jam-out-title1 .jam-out-title-letter:nth-child(14) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fourteen); }
#jam-out-title1 .jam-out-title-letter:nth-child(15) { background-position: top 1000% left 1000%; }
#jam-out-title1 .jam-out-title-letter:nth-child(16) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-eight); }
#jam-out-title1 .jam-out-title-letter:nth-child(17) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#jam-out-title1 .jam-out-title-letter:nth-child(18) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-thirteen); }
#jam-out-title1 .jam-out-title-letter:nth-child(19) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title1 .jam-out-title-letter:nth-child(20) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-seven); }
#jam-out-title1 .jam-out-title-letter:nth-child(21) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }

#jam-out-title2 {
  position: absolute;
  top: calc(8% + 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#jam-out-title2 .jam-out-title-letter {
  display: inline-block;
  height: 34px;
  width: 30px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 500px auto;
}
#jam-out-title2 .jam-out-title-letter:nth-child(1) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }
#jam-out-title2 .jam-out-title-letter:nth-child(2) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#jam-out-title2 .jam-out-title-letter:nth-child(3) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#jam-out-title2 .jam-out-title-letter:nth-child(4) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-ten); }
#jam-out-title2 .jam-out-title-letter:nth-child(5) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title2 .jam-out-title-letter:nth-child(6) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-thirteen); }
#jam-out-title2 .jam-out-title-letter:nth-child(7) { background-position: top 1000% left 1000%; }
#jam-out-title2 .jam-out-title-letter:nth-child(8) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title2 .jam-out-title-letter:nth-child(9) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fourteen); }
#jam-out-title2 .jam-out-title-letter:nth-child(10) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-four); }
#jam-out-title2 .jam-out-title-letter:nth-child(11) { background-position: top 1000% left 1000%; }
#jam-out-title2 .jam-out-title-letter:nth-child(12) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#jam-out-title2 .jam-out-title-letter:nth-child(13) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title2 .jam-out-title-letter:nth-child(14) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-three); }
#jam-out-title2 .jam-out-title-letter:nth-child(15) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-twelve); }
#jam-out-title2 .jam-out-title-letter:nth-child(16) { background-position: top 1000% left 1000%; }
#jam-out-title2 .jam-out-title-letter:nth-child(17) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-four); }
#jam-out-title2 .jam-out-title-letter:nth-child(18) { background-position: top 1000% left 1000%; }
#jam-out-title2 .jam-out-title-letter:nth-child(19) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-ten); }
#jam-out-title2 .jam-out-title-letter:nth-child(20) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-title2 .jam-out-title-letter:nth-child(21) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-thirteen); }
#jam-out-title2 .jam-out-title-letter:nth-child(22) { background-position: top 1000% left 1000%; }
#jam-out-title2 .jam-out-title-letter:nth-child(23) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#jam-out-title2 .jam-out-title-letter:nth-child(24) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#jam-out-title2 .jam-out-title-letter:nth-child(25) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }

#press-spacebar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#press-spacebar .press-spacebar-letter {
  display: inline-block;
  height: 50px;
  width: 40px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 700px auto;
}
#press-spacebar .press-spacebar-letter:nth-child(1) { animation: p var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(2) { animation: r var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(3) { animation: e var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(4) { animation: s var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(5) { animation: s var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(6) { background-position: top 1000% left 1000%; }
#press-spacebar .press-spacebar-letter:nth-child(7) { animation: s var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(8) { animation: p var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(9) { animation: a var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(10) { animation: c var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(11) { animation: e var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(12) { animation: b var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(13) { animation: a var(--text-sprite-animation); }
#press-spacebar .press-spacebar-letter:nth-child(14) { animation: r var(--text-sprite-animation); }

#instructions1 {
  position: absolute;
  top: calc(95% - 100px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#instructions1 .instructions-letter {
  display: inline-block;
  height: 34px;
  width: 30px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 500px auto;
}
#instructions1 .instructions-letter:nth-child(1) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-eleven); }
#instructions1 .instructions-letter:nth-child(2) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#instructions1 .instructions-letter:nth-child(3) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-ten); }
#instructions1 .instructions-letter:nth-child(4) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#instructions1 .instructions-letter:nth-child(5) { background-position: top 1000% left 1000%; }
#instructions1 .instructions-letter:nth-child(6) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-seven); }
#instructions1 .instructions-letter:nth-child(7) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-four); }
#instructions1 .instructions-letter:nth-child(8) { background-position: top var(--text-sprite-row-nine) left var(--text-sprite-col-six); }
#instructions1 .instructions-letter:nth-child(9) { background-position: top 1000% left 1000%; }
#instructions1 .instructions-letter:nth-child(10) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }
#instructions1 .instructions-letter:nth-child(11) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#instructions1 .instructions-letter:nth-child(12) { background-position: top 1000% left 1000%; }
#instructions1 .instructions-letter:nth-child(13) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-ten); }
#instructions1 .instructions-letter:nth-child(14) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#instructions1 .instructions-letter:nth-child(15) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-thirteen); }
#instructions1 .instructions-letter:nth-child(16) { background-position: top 1000% left 1000%; }
#instructions1 .instructions-letter:nth-child(17) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#instructions1 .instructions-letter:nth-child(18) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#instructions1 .instructions-letter:nth-child(19) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }

#instructions2 {
  position: absolute;
  top: calc(95% - 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#instructions2 .instructions-letter {
  display: inline-block;
  height: 34px;
  width: 30px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 500px auto;
}
#instructions2 .instructions-letter:nth-child(1) {
  background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-seven);
  transform: rotate(90deg);
}
#instructions2 .instructions-letter:nth-child(2) { background-position: top 1000% left 1000%; }
#instructions2 .instructions-letter:nth-child(3) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#instructions2 .instructions-letter:nth-child(4) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fourteen); }
#instructions2 .instructions-letter:nth-child(5) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-four); }
#instructions2 .instructions-letter:nth-child(6) { background-position: top 1000% left 1000%; }
#instructions2 .instructions-letter:nth-child(7) {
  background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-seven);
  transform: rotate(-90deg);
}
#instructions2 .instructions-letter:nth-child(8) { background-position: top 1000% left 1000%; }
#instructions2 .instructions-letter:nth-child(9) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }
#instructions2 .instructions-letter:nth-child(10) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#instructions2 .instructions-letter:nth-child(11) { background-position: top 1000% left 1000%; }
#instructions2 .instructions-letter:nth-child(12) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-three); }
#instructions2 .instructions-letter:nth-child(13) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-eight); }
#instructions2 .instructions-letter:nth-child(14) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#instructions2 .instructions-letter:nth-child(15) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fourteen); }
#instructions2 .instructions-letter:nth-child(16) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-seven); }
#instructions2 .instructions-letter:nth-child(17) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#instructions2 .instructions-letter:nth-child(18) { background-position: top 1000% left 1000%; }
#instructions2 .instructions-letter:nth-child(19) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-thirteen); }
#instructions2 .instructions-letter:nth-child(20) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#instructions2 .instructions-letter:nth-child(21) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#instructions2 .instructions-letter:nth-child(22) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-nine); }
#instructions2 .instructions-letter:nth-child(23) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-three); }

#github {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
}
#github .github-letter {
  display: inline-block;
  height: 18px;
  width: 16px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 250px auto;
}
#github .github-letter:nth-child(1) { animation: v var(--text-sprite-animation); }
#github .github-letter:nth-child(2) { animation: i var(--text-sprite-animation); }
#github .github-letter:nth-child(3) { animation: e var(--text-sprite-animation); }
#github .github-letter:nth-child(4) { animation: w var(--text-sprite-animation); }
#github .github-letter:nth-child(5) { background-position: top 1000% left 1000%; }
#github .github-letter:nth-child(6) { animation: o var(--text-sprite-animation); }
#github .github-letter:nth-child(7) { animation: n var(--text-sprite-animation); }
#github .github-letter:nth-child(8) { background-position: top 1000% left 1000%; }
#github .github-letter:nth-child(9) { animation: g var(--text-sprite-animation); }
#github .github-letter:nth-child(10) { animation: i var(--text-sprite-animation); }
#github .github-letter:nth-child(11) { animation: t var(--text-sprite-animation); }
#github .github-letter:nth-child(12) { animation: h var(--text-sprite-animation); }
#github .github-letter:nth-child(13) { animation: u var(--text-sprite-animation); }
#github .github-letter:nth-child(14) { animation: b var(--text-sprite-animation); }

/* -------------------------- */
/* --- JAM OUT BACKGROUND --- */
/* -------------------------- */

#jam-out-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(./assets/sprites/jam-out-bg-sprite.png); /* Thanks to sprite creator Ryan914 */
  background-repeat: no-repeat;
  background-position: top 0% left 0%;
  background-size: calc(100% * 4);
}
#jam-out-frame {
  visibility: visible;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(./assets/sprites/jam-out-frame.png); /* Thanks to sprite creator Ryan914 */
  background-repeat: no-repeat;
  background-position: top 50% left 50%;
  background-size: 500px;
  animation: checkeredFrame 15s steps(4, end) 0s infinite normal none;
}
#jam-out-border {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 50px solid #000;
  box-sizing: border-box;
}
#jam-out-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
}
#jam-out-text .jam-out-text-letter {
  display: inline-block;
  height: 25px;
  width: 20px;
  background-image: url(./assets/sprites/font-sprite.png); /* Thanks to sprite creator DrKelexo */
  background-repeat: no-repeat;
  background-size: 350px auto;
}
#jam-out-text .jam-out-text-letter:nth-child(1) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(2) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-three); }
#jam-out-text .jam-out-text-letter:nth-child(3) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#jam-out-text .jam-out-text-letter:nth-child(4) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#jam-out-text .jam-out-text-letter:nth-child(5) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#jam-out-text .jam-out-text-letter:nth-child(6) { background-position: top 1000% left 1000%; }
#jam-out-text .jam-out-text-letter:nth-child(7) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#jam-out-text .jam-out-text-letter:nth-child(8) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(9) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(10) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-three); }
#jam-out-text .jam-out-text-letter:nth-child(11) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }
#jam-out-text .jam-out-text-letter:nth-child(12) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-two); }
#jam-out-text .jam-out-text-letter:nth-child(13) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(14) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-three); }
#jam-out-text .jam-out-text-letter:nth-child(15) { background-position: top 1000% left 1000%; }
#jam-out-text .jam-out-text-letter:nth-child(16) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-five); }
#jam-out-text .jam-out-text-letter:nth-child(17) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-fifteen); }
#jam-out-text .jam-out-text-letter:nth-child(18) { background-position: top 1000% left 1000%; }
#jam-out-text .jam-out-text-letter:nth-child(19) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(20) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-one); }
#jam-out-text .jam-out-text-letter:nth-child(21) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-six); }
#jam-out-text .jam-out-text-letter:nth-child(22) { background-position: top var(--text-sprite-row-eight) left var(--text-sprite-col-four); }
#jam-out-text .jam-out-text-letter:nth-child(23) { background-position: top var(--text-sprite-row-seven) left var(--text-sprite-col-five); }

/* --------------------- */
/* --- TOEJAM / EARL --- */
/* --------------------- */

#toejam-box, #earl-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 110px;
  z-index: 1;
}
#toejam-box {
  height: 110px;
  width: 110px;
  top: 51%;
  left: 44%;
}
#earl-box {
  height: 120px;
  width: 120px;
  top: 50%;
  left: 54%;
}
#toejam, #earl {
  position: absolute;
  height: 100%;
  width: 100%;
}
#toejam {
  background-image: url(./assets/sprites/toejam-dancing-sprite.png); /* Thanks to sprite creator Evil_Kefka */
  background-repeat: no-repeat;
  background-position: top 0% left 0%;
  background-size: 1500px auto;
  animation: toejamIdle .5s steps(2, end) 0s infinite normal none;
}
#earl {
  background-image: url(./assets/sprites/earl-dancing-sprite.png); /* Thanks to sprite creator Evil_Kefka */
  background-repeat: no-repeat;
  background-position: top -2% left 0%;
  background-size: 1500px auto;
  animation: earlIdle .5s steps(2, end) 0s infinite normal none;
}

/* ---------- */
/* ANIMATIONS */
/* ---------- */

/* MISC */
@keyframes checkeredFrame {
  0% { visibility: hidden; }
  20% { visibility: visible; }
  40% { visibility: hidden; }
  100% { visibility: hidden; }
}
@keyframes toejamIdle { 0% { background-position: top -4% left 0% } 100% { background-position: top -4% left 16% } }
@keyframes earlIdle { 0% { background-position: top -2% left 0% } 100% { background-position: top -2% left 15% } }

/* TOEJAM DANCE */
#toejam.dance1 { animation: toejamDance1 var(--dance-animation) }
@keyframes toejamDance1 {
  0% { background-position: top 47.5% left 0% }
  100% { background-position: top 47.5% left 16.5% }
}
#toejam.dance2 { animation: toejamDance2 var(--dance-animation) }
@keyframes toejamDance2 {
  0% { background-position: top 47.5% left 49% }
  100% { background-position: top 47.5% left 65% }
}
#toejam.dance3 { animation: toejamDance3 var(--dance-animation) }
@keyframes toejamDance3 {
  0% { background-position: top 98% left 0% }
  100% { background-position: top 98% left 50.5% }
}
#toejam.dance4 { animation: toejamDance4 var(--dance-animation) }
@keyframes toejamDance4 {
  0% { background-position: top 98% left 73% }
  100% { background-position: top 98% left 89% }
}
#toejam.dance5 { animation: toejamDance5 var(--dance-animation) }
@keyframes toejamDance5 {
  0% { background-position: top 98% left 0% }
  100% { background-position: top 98% left 18% }
}
#toejam.dance6 { animation: toejamDance6 var(--dance-animation) }
@keyframes toejamDance6 {
  0% { background-position: top 98% left 81% }
  100% { background-position: top 98% left 97.5% }
}
#toejam.dance7 { animation: toejamDance7 var(--dance-animation) }
@keyframes toejamDance7 {
  0% { background-position: top 98% left 43% }
  100% { background-position: top 98% left 58% }
}
#toejam.dance8 { animation: toejamDance8 var(--dance-animation) }
@keyframes toejamDance8 {
  0% { background-position: top 47.5% left 34% }
  100% { background-position: top 47.5% left 65% }
}
#toejam.dance9 { animation: toejamDance9 var(--dance-animation) }
@keyframes toejamDance9 {
  0% { background-position: top 47% left 8.5% }
  100% { background-position: top 47% left 25.5% }
}
#toejam.dance0 { animation: toejamDance0 var(--dance-animation) }
@keyframes toejamDance0 {
  0% { background-position: top 47% left 0% }
  100% { background-position: top 149% left 0% }
}

/* EARL DANCE */
#earl.dance1 { animation: earlDance1 var(--dance-animation) }
@keyframes earlDance1 {
  0% { background-position: top 48% left 0% }
  100% { background-position: top 48% left 16.5% }
}
#earl.dance2 { animation: earlDance2 var(--dance-animation) }
@keyframes earlDance2 {
  0% { background-position: top 42% left 49.5% }
  100% { background-position: top 42% left 67.5% }
}
#earl.dance3 { animation: earlDance3 var(--dance-animation) }
@keyframes earlDance3 {
  0% { background-position: top 99% left 0% }
  100% { background-position: top 99% left 50.5% }
}
#earl.dance4 { animation: earlDance4 var(--dance-animation) }
@keyframes earlDance4 {
  0% { background-position: top 98% left 76% }
  100% { background-position: top 98% left 92% }
}
#earl.dance5 { animation: earlDance5 var(--dance-animation) }
@keyframes earlDance5 {
  0% { background-position: top 98% left 0% }
  100% { background-position: top 98% left 18% }
}
#earl.dance6 { animation: earlDance6 var(--dance-animation) }
@keyframes earlDance6 {
  0% { background-position: top 98% left 84% }
  100% { background-position: top 98% left 102% }
}
#earl.dance7 { animation: earlDance7 var(--dance-animation) }
@keyframes earlDance7 {
  0% { background-position: top 98% left 43.5% }
  100% { background-position: top 98% left 58.5% }
}
#earl.dance8 { animation: earlDance8 var(--dance-animation) }
@keyframes earlDance8 {
  0% { background-position: top 42% left 49.5% }
  100% { background-position: top 55% left 20% }
}
#earl.dance9 { animation: earlDance9 var(--dance-animation) }
@keyframes earlDance9 {
  0% { background-position: top 48% left 8% }
  100% { background-position: top 50% left 25% }
}
#earl.dance0 { animation: earlDance0 var(--dance-animation) }
@keyframes earlDance0 {
  0% { background-position: top 48% left 0% }
  100% { background-position: top 150% left 0% }
}

/* LETTER JITTERS */
@keyframes a {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-one); }
  to { background-position: top 65% left var(--text-sprite-col-one); }
}
@keyframes b {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-two); }
  to { background-position: top 65% left var(--text-sprite-col-two); }
}
@keyframes c {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-three); }
  to { background-position: top 65% left var(--text-sprite-col-three); }
}
@keyframes e {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-five); }
  to { background-position: top 65% left var(--text-sprite-col-five); }
}
@keyframes g {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-seven); }
  to { background-position: top 65% left var(--text-sprite-col-seven); }
}
@keyframes h {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-eight); }
  to { background-position: top 65% left var(--text-sprite-col-eight); }
}
@keyframes i {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-nine); }
  to { background-position: top 65% left var(--text-sprite-col-nine); }
}
@keyframes o {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-fifteen); }
  to { background-position: top 65% left var(--text-sprite-col-fifteen); }
}
@keyframes n {
  from { background-position: top var(--text-sprite-row-one) left var(--text-sprite-col-fourteen); }
  to { background-position: top 65% left var(--text-sprite-col-fourteen); }
}
@keyframes p {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-one); }
  to { background-position: top 75% left var(--text-sprite-col-one); }
}
@keyframes r {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-three); }
  to { background-position: top 75% left var(--text-sprite-col-three); }
}
@keyframes s {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-four); }
  to { background-position: top 75% left var(--text-sprite-col-four); }
}
@keyframes t {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-five); }
  to { background-position: top 75% left var(--text-sprite-col-five); }
}
@keyframes u {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-six); }
  to { background-position: top 75% left var(--text-sprite-col-six); }
}
@keyframes v {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-seven); }
  to { background-position: top 75% left var(--text-sprite-col-seven); }
}
@keyframes w {
  from { background-position: top var(--text-sprite-row-two) left var(--text-sprite-col-eight); }
  to { background-position: top 75% left var(--text-sprite-col-eight); }
}
@keyframes dash {
  from { background-position: top var(--text-sprite-row-three) left var(--text-sprite-col-four); }
  to { background-position: top 84% left var(--text-sprite-col-four); }
}

/* BACKGROUND SHIFTY ANIMATIONS */
#jam-out-bg.shifty1 { animation: shifty1 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty2 { animation: shifty2 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty3 { animation: shifty3 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty4 { animation: shifty4 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty5 { animation: shifty5 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty6 { animation: shifty6 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty7 { animation: shifty7 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty8 { animation: shifty8 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty9 { animation: shifty9 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty10 { animation: shifty10 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty11 { animation: shifty11 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty12 { animation: shifty12 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty13 { animation: shifty13 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty14 { animation: shifty14 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty15 { animation: shifty15 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty16 { animation: shifty16 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty17 { animation: shifty17 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty18 { animation: shifty18 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty19 { animation: shifty19 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty20 { animation: shifty20 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty21 { animation: shifty21 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty22 { animation: shifty22 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty23 { animation: shifty23 .5s steps(2, end) 0s infinite normal none; }
#jam-out-bg.shifty24 { animation: shifty24 .5s steps(2, end) 0s infinite normal none; }
@keyframes shifty1 { from { background-position: top 0% left 0% } to { background-position: top 0% left 66% } }
@keyframes shifty2 { from { background-position: top 0% left 66% } to { background-position: top 0% left 132% } }
@keyframes shifty3 { from { background-position: top 8% left 0% } to { background-position: top 8% left 66% } }
@keyframes shifty4 { from { background-position: top 8% left 66% } to { background-position: top 8% left 132% } }
@keyframes shifty5 { from { background-position: top 17% left 0% } to { background-position: top 17% left 66% } }
@keyframes shifty6 { from { background-position: top 17% left 66% } to { background-position: top 17% left 132% } }
@keyframes shifty7 { from { background-position: top 25% left 0% } to { background-position: top 25% left 66% } }
@keyframes shifty8 { from { background-position: top 25% left 66% } to { background-position: top 25% left 132% } }
@keyframes shifty9 { from { background-position: top 33% left 0% } to { background-position: top 33% left 66% } }
@keyframes shifty10 { from { background-position: top 33% left 66% } to { background-position: top 33% left 132% } }
@keyframes shifty11 { from { background-position: top 42% left 0% } to { background-position: top 42% left 66% } }
@keyframes shifty12 { from { background-position: top 42% left 66% } to { background-position: top 42% left 132% } }
@keyframes shifty13 { from { background-position: top 50% left 0% } to { background-position: top 50% left 66% } }
@keyframes shifty14 { from { background-position: top 50% left 66% } to { background-position: top 50% left 132% } }
@keyframes shifty15 { from { background-position: top 58% left 0% } to { background-position: top 58% left 66% } }
@keyframes shifty16 { from { background-position: top 58% left 66% } to { background-position: top 58% left 132% } }
@keyframes shifty17 { from { background-position: top 66% left 0% } to { background-position: top 66% left 66% } }
@keyframes shifty18 { from { background-position: top 66% left 66% } to { background-position: top 66% left 132% } }
@keyframes shifty19 { from { background-position: top 75% left 0% } to { background-position: top 75% left 66% } }
@keyframes shifty20 { from { background-position: top 75% left 66% } to { background-position: top 75% left 132% } }
@keyframes shifty21 { from { background-position: top 83% left 0% } to { background-position: top 83% left 66% } }
@keyframes shifty22 { from { background-position: top 83% left 66% } to { background-position: top 83% left 132% } }
@keyframes shifty23 { from { background-position: top 91% left 0% } to { background-position: top 91% left 66% } }
@keyframes shifty24 { from { background-position: top 91% left 66% } to { background-position: top 91% left 132% } }


@media screen and (max-width : 650px) {
  #mobile {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background-image: url(./assets/space-background.png);
    background-repeat: no-repeat;
    background-position: top 0% left 0%;
    background-size: cover;
  }
  #mobile:after {
    font-family: Arial, Helvetica, sans-serif;
    content: 'Please jam out on a desktop.';
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    display: inline-block;
    width: 100vw;
    text-align: center;
  }
  #space-background {
    overflow: hidden;
  }
}